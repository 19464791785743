body {
    background-color: #232F3E;
}

.container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    position: relative;
}

.left-col {
    background-color: #232F3E;
    width: 25vw;
}

.center-col {
    height: 100vh;
    width: 50vw;
    background-color: #121a25;
}

.right-col {
    background-color: #232F3E;
    width: 25vw;
}

.logo-menu-row {
    display: flex;
    flex-direction: row;
}

.logo {
    width: 40%;
}

.logo img {
    width: 250px;
    margin: 20px 0 0 20px;
}

.menu {
    width: 60%;
    margin-top: 15px;
}

.menu ul {
    list-style-type: none;
    margin: 0 0 0 40px;
    padding: 0;
}

.menu ul li {
    float: left;
}

.menu ul li a {
    display: block;
    color: white;
    text-align: center;
    padding: 4px 14px;
    text-decoration: none;
    font-family: 'Kanit-Regular';
}

.menu ul li a:hover {
    background-color: #d45b15;
}

.title-text-container {
    margin-top: 95px;
    display: flex;
    flex-direction: row;
    font-family: 'Kanit-Regular';
    width: 100%;
    color: #fff;
    font-size: 30px;
}

.title-text-container p {
    margin: 0 auto;
}

.down-counter {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
}

.body-text {
    display: flex;
    flex-direction: row;
}

.body-text p {
    color: #fff;
    padding: 15px;
    text-align: justify;
}

.line-break {
    margin: 25px 0 25px 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(to right,
            #e5dfe9 0%,
            #d45b15 50%,
            rgba(224, 109, 15, 0.95) 50%,
            rgba(223, 217, 230, 0.95) 100%);
}

.call-for-speaker-container {
    display: flex;
    flex-direction: row;
    font-family: 'Kanit-Regular';
    color: #fff;
    margin-top: 5px;
}

.speaker-title {
    font-size: 30px;
    width: 100%;
    text-align: center;
    font-family: 'Kanit-Regular';
    color: #fff;
}

.speaker-text {
    padding: 0 15px 0 15px;
    text-align: justify;
    font-family: 'Kanit-Regular';
    color: #fff;
}

.speaker-button {
    text-align: center;
}

.call-for-papers-button {
    font-family: 'Kanit-Regular';
    transition-duration: 0.4s;
    background-color: #c54e0e;
    border: #c54e0e 2px solid;
    border-radius: 2px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 25px;
}

.call-for-papers-button:hover {
    font-family: 'Kanit-Regular';
    background-color: #fff;
    color: #c54e0e;
    border: #c54e0e 2px solid;
    border-radius: 2px;
}

.call-for-papers-button-top {
    font-family: 'Kanit-Regular';
    transition-duration: 0.4s;
    background-color: #c54e0e;
    border: #c54e0e 2px solid;
    border-radius: 2px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 55px;
}

.call-for-papers-button-top:hover {
    font-family: 'Kanit-Regular';
    background-color: #fff;
    color: #c54e0e;
    border: #c54e0e 2px solid;
    border-radius: 2px;
}

@media screen and (min-width:1000px) and (max-width:2500px) {

    .left-col {
        background-color: #232F3E;
        width: 15vw;
    }

    .center-col {
        height: 150vh;
        width: 70vw;
        background-color: #121a25;
    }

    .right-col {
        background-color: #232F3E;
        width: 15vw;
    }

    .menu ul {
        list-style-type: none;
        margin: 0 0 0 15px;
        padding: 0;
    }

    .call-for-papers-button-top {
        font-family: 'Kanit-Regular';
        transition-duration: 0.4s;
        background-color: #c54e0e;
        border: #c54e0e 2px solid;
        border-radius: 2px;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin-left: 20px;
    }
}

@media (min-width:300px) and (max-width:1000px) {
    .left-col {
        background-color: #232F3E;
        width: 0vw;
    }

    .center-col {
        height: 100vh;
        width: 100vw;
        background-color: #232F3E;
    }

    .right-col {
        background-color: #232F3E;
        width: 0vw;
    }

    .logo {
        width: 40%;
    }

    .logo img {
        width: 110px;
        margin: 20px 0 0 20px;
    }

    .call-for-papers-button-top {
        display: none;
    }

    .title-text-container {
        margin-top: 35px;
        margin-left: 15px;
        display: flex;
        flex-direction: row;
        font-family: 'Kanit-Regular';
        width: 100%;
        color: #fff;
        font-size: 30px;
    }

    .title-text-container p {
        margin: 0 auto;
    }

    /* timer */

    .counter__box {
        border-radius: 50%;
        text-align: center;
        background: blue;
        width: 60px !important;
        height: 60px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0.25rem;
    }

    .dots {
        display: none;
    }

    .counter__duration {
        font-size: 0.6rem !important;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
        color: #eee;
    }
}

/* Timer */

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Roboto:wght@700&display=swap");

body {
    display: grid;
    place-items: center;
    height: 90vh;
    font-family: "Nunito Sans", sans-serif;
    color: #002e46;
}

.countdown {
    /* background: #eee; */
    height: 210px;
    width: 640px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    flex-wrap: wrap;
}

.timer,
.signup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.timer h3 {
    font-weight: 700;
    font-size: 22px;
}

.signup h1 {
    max-width: 350px;
    text-align: center;
    font-weight: 400;
    font-size: 26px;
}

.btn {
    display: inline-block;
    min-width: 280px;
    padding: 10px 28px;
    background: #009edb;
    font-size: 26px;
    color: white;
    text-decoration: none;
    text-align: center;
}

.counter {
    display: flex;
    max-width: fit-content;
    gap: 0.75em;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}

.counter__box {
    border-radius: 50%;
    text-align: center;
    background: blue;
    width: 103px;
    height: 103px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.25rem;
}

.counter__time {
    font-size: 3rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

.counter__duration {
    font-size: 0.8rem;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
    color: #eee;
}

/* HELPERS */

.dots {
    font-size: 52px;
    color: #ffffff;
    margin: -0.75rem;
    padding: 12px;
}

.exp {
    font-size: 52px;
    font-family: "Roboto", sans-serif;
}

.black-white {
    background: linear-gradient(to top,
            #002e46 0%,
            #002e46 50%,
            #4d707f 50%,
            #4d707f 100%);
}

.sky-blue {
    background: linear-gradient(to top,
            #743600 0%,
            #743600 50%,
            rgba(151, 51, 1, 0.95) 50%,
            rgba(232, 134, 36, 0.95) 100%);
}

.green {
    background: linear-gradient(to top,
            #025851 0%,
            #025851 50%,
            rgba(2, 81, 75, 0.95) 50%,
            rgba(62, 228, 189, 0.95) 100%);
}

.purple {
    background: linear-gradient(to top,
            #3c025b 0%,
            #3c025b 50%,
            rgba(68, 2, 81, 0.95) 50%,
            rgba(164, 109, 219, 0.95) 100%);
}